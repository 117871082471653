<template>
  <div class="SignInContainer">
    <div>
      <input type="text"/>
      <input type="password" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>

</style>
